<template lang="pug">
.page-builder
  breadcrumb-bar(v-if="page && page.show_breadcrumbs")
    breadcrumb-button(slot="left" icon-left="fa-angle-left" @click.native="$router.go(-1)") Vorige pagina
  .row(
    v-for="row in rows"
    :class="[row.styling]"
  )
    .wrapper(
      :class="padding(row)"
    )
      template(v-if="row.styling === 'default'")
        template(v-for="block in row.blocks")
          text-header(v-if="block.type === 'textheader'" :h1="block.h1" :h2="block.h2")
          page-image(v-if="block.type === 'image'" :block="block")
          page-content(v-if="block.type === 'text'" :block="block")
          image-text(v-if="block.type === 'imagetext'" :block="block" :color="block.bg_color")
          spacer(v-if="block.type === 'spacer'" :block="block")
          custom-iframe(v-if="block.type === 'iframe'" :block="block")
      template(v-if="row.styling === 'header_full'")
        template(v-for="block in row.blocks")
          home-header(v-if="block.type === 'header-home'" :block="block" :color="block.bg_color")
          image-text(v-if="block.type === 'header'" :block="block")
      template(v-if="row.styling === 'header_half'")
        template(v-for="block in row.blocks")
          image-text(v-if="block.type === 'header'" :block="block")
      template(v-if="row.styling === 'customer_review'")
        review-slider(:color="row.bg_color")
          div.slider-slide.review-slide(v-for="(block, reviewIndex) in row.blocks")
            review-card(:key="reviewIndex" :block="block")
      template(v-if="row.styling === 'products'")
        template(v-for="block in row.blocks")
          product-slider(
            v-if="block.type === 'discount-products'"
            :block="block"
            :has-background="true"
            :color="row.bg_color"
          )
            product-card.slider-slide(
              v-for="product in block.products.data"
              :key="product.id"
              :product="product"
              :mobile-styles="false"
            )
          product-slider(v-if="block.type === 'products'" :block="block")
            product-card.slider-slide(
              v-for="product in block.products.data"
              :key="product.id"
              :product="product"
              :border="true"
              :mobile-styles="false"
            )
      template(v-if="row.styling === 'news_overview'")
        template(v-for="block in row.blocks")
          story-overview(v-if="block.type === 'news-items'" :title="block.h1" :stories="block.stories.data" :button="block.action_link" :mobile-limit="2")
          story-header(v-if="block.type === 'header'" :data="block")
          page-content(v-if="block.type === 'texttitle'" :block="block")
      template(v-if="row.styling === 'recipes_overview'")
        template(v-for="block in row.blocks")
          recipe-slider(v-if="block.type === 'recipes'" :title="block.h1 ? block.h1 : 'Ons recept voor heerlijk eten'" :block="block" :data="block.recipes.data")
          recipe-header(v-if="block.type === 'header'" :block="block")
      template(v-if="row.styling === 'farmers_overview'")
        template(v-for="block in row.blocks")
          farmer-overview(v-if="block.type === 'farmers-overview'" :data="block")
          image-header(v-if="block.type === 'image-text-header'" :data="block")
          page-content(v-if="block.type === 'texttitle'" :block="block")
      template(v-if="row.styling === 'categories_overview'")
        template(v-for="block in row.blocks")
          text-header(v-if="block.type === 'textheader'" :h1="block.h1")
          category-wrapper(:data="categories" v-if="block.type === 'main-shop-categories'")
          page-image(v-if="block.type === 'image'" :block="block")
  modal(
    v-model="zipcode.open"
    :primary-button="{ text: 'Check mijn postcode' }"
    :secondary-button="false"
    @submit="checkZipcode"
  )
    template(v-slot:title) Postcode check
    template(v-slot:body)
      .page-content
        p Doe de postcodecheck om er achter te komen wanneer we bij jou bezorgen en maak direct een account aan.
      form-input(v-model="zipcode.value" placeholder="Je postcode")
  modal(
    :value="pickup.open && !!pickupPoints.length"
    :primary-button="false"
    :secondary-button="false"
    size="large"
    @submit="checkZipcode"
    @input="pickup.open = !pickup.open"
  )
    template(v-slot:title) Hofweb bezorggebied en afhaalpunten
    template(v-slot:body)
      .page-content
        p Zoom in en klik op jouw locatie. Je kan dan zien welke dag wij bij je bezorgen, wie het bezorgd en wat de verzendkosten zijn.
        p
          i.far.fa-map-marker-alt
          |  = een afhaalpunt. Hier kan je een bestelling afhalen.
      pickup-map(
        :pickup-points="pickupPoints"
        :zoom="9"
        :use-button="false"
      )
</template>

<script>
export default {
  name: 'PageBuilder',
  components: {
    'breadcrumb-bar': () => import('@/components/breadcrumbs/breadcrumbBar'),
    'breadcrumb-button': () => import('@/components/headers/homeHeader'),
    'home-header': () => import('@/components/headers/homeHeader'),
    'text-header': () => import('@/components/headers/textHeader'),
    'image-text': () => import('@/components/headers/imageText'),
    'page-image': () => import('@/components/page/pageImage'),
    'page-content': () => import('@/components/page/pageContent'),
    'review-slider': () => import('@/components/review/reviewSlider'),
    'review-card': () => import('@/components/review/reviewCard'),
    'product-slider': () => import('@/components/products/productSlider'),
    'product-card': () => import('@/components/products/productCard'),
    'news-grid': () => import('@/components/news/newsGrid'),
    'news-card': () => import('@/components/news/newsCard'),
    'recipe-slider': () => import('@/components/recipes/recipeSlider'),
    'recipe-header': () => import('@/components/recipes/recipeHeader'),
    'form-input': () => import('@/components/form/formInput'),
    'pickup-map': () => import('@/components/pickup/pickupMap'),
    'farmer-overview': () => import('@/components/farmers/farmerOverview'),
    'story-overview': () => import('@/components/stories/storyOverview'),
    'image-header': () => import('@/components/headers/imageHeader'),
    'story-header': () => import('@/components/stories/storyHeader'),
    'category-wrapper': () => import('@/components/category/categoryWrapper'),
    'custom-iframe': () => import('@/components/customIframe'),
    modal: () => import('@/components/modal'),
    spacer: () => import('@/components/spacer'),
  },
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      zipcode: {
        open: false,
        value: '',
      },
      pickup: {
        open: false,
      },
    };
  },
  computed: {
    rows() {
      if (this.page && this.page.page) {
        return this.page.page.data.rows.data.map((row) => ({ ...row, blocks: row.blocks.data }));
      }
      if (this.page && this.page.rows) {
        return this.page.rows.data.map((row) => ({ ...row, blocks: row.blocks.data }));
      }
      return [];
    },
    categories() {
      return this.$store.getters['api/mainNavigation'].map((category) => ({
        ...category,
        path: `/${category.path}`,
        conversions: category.media_conversions?.['tile-images'] || {},
      }));
    },
    pickupPoints() {
      return this.$store.getters['api/pickupPoints'];
    },
  },
  mounted() {
    setTimeout(() => {
      const zipcodeCheck = document.querySelector('[data-action="show-zipcode-check"]');
      const pickupPoints = document.querySelector('[data-action="show-pickuppoints"]');

      if (zipcodeCheck && !this.$auth.loggedIn) {
        zipcodeCheck.addEventListener('click', () => { this.zipcode.open = !this.zipcode.open; });
      }

      if (zipcodeCheck && this.$auth.loggedIn) {
        zipcodeCheck.classList.add('disabled');
      }

      if (pickupPoints) {
        pickupPoints.addEventListener('click', async () => {
          if (!this.pickup.open && !this.pickupPoints.length) {
            await this.$store.dispatch('api/getPickupPoints');
          }
          this.pickup.open = !this.pickup.open;
        });
      }
    }, 100);
  },
  methods: {
    padding(row) {
      return { 'pt-0': row.remove_top_padding, 'pb-0': row.remove_bottom_padding };
    },
    checkZipcode() {
      this.$router.push(`/registreren/${this.zipcode.value}`);
    },
  },
};
</script>

<style lang="scss">
.page-builder {

  .row {
    width: 100%;

    .wrapper {
      display: flex;
      padding-top: 30px;
      justify-content: center;

      &.pt-0 {
        padding-top: 0;
      }

      &.pb-0 {
        padding-bottom: 0;
      }
    }

    &.default {

      .wrapper {
        max-width: 1300px;
        margin: 0 auto;
        @media screen and (max-width: $breakpoint-md) {
          flex-direction: column;
        }
      }
    }

    &.header_half {

      .wrapper {
        max-width: 1300px;
        margin: 0 auto;
        @media screen and (max-width: $breakpoint-md) {
          flex-direction: column;
        }
      }
    }

    &.text_header {
      padding: 80px 0 30px 0;

      .wrapper {
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 20px;
      }
    }

    &.news_overview {

      .wrapper {
        max-height: 1100px;
        background-color: $oldLace;
        background-image: url('~assets/images/news-background.png');

        @media screen and (max-width: $breakpoint-md) {
          max-height: 1200px;
        }
      }
    }

    &.farmers_overview {

      .page-content.content-padding {
        margin: 0 auto;
        text-align: center;

        * {
          color: $hunterGreen;
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }

    &.categories_overview {

      .wrapper{
        width: 100%;

        .category-wrapper{
          max-width: 1300px;
          margin: 0 auto;
        }
      }

    }
  }
}
</style>
