// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/news-background.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-builder .row{width:100%}.page-builder .row .wrapper{display:flex;padding-top:30px;justify-content:center}.page-builder .row .wrapper.pt-0{padding-top:0}.page-builder .row .wrapper.pb-0{padding-bottom:0}.page-builder .row.default .wrapper{max-width:1300px;margin:0 auto}@media screen and (max-width:940px){.page-builder .row.default .wrapper{flex-direction:column}}.page-builder .row.header_half .wrapper{max-width:1300px;margin:0 auto}@media screen and (max-width:940px){.page-builder .row.header_half .wrapper{flex-direction:column}}.page-builder .row.text_header{padding:80px 0 30px}.page-builder .row.text_header .wrapper{max-width:1300px;margin:0 auto;padding:0 20px}.page-builder .row.news_overview .wrapper{max-height:1100px;background-color:#f4eee2;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@media screen and (max-width:940px){.page-builder .row.news_overview .wrapper{max-height:1200px}}.page-builder .row.farmers_overview .page-content.content-padding{margin:0 auto;text-align:center}.page-builder .row.farmers_overview .page-content.content-padding *{color:#3d6136;font-weight:600;font-size:18px;line-height:30px}.page-builder .row.categories_overview .wrapper{width:100%}.page-builder .row.categories_overview .wrapper .category-wrapper{max-width:1300px;margin:0 auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
